import React, { Component, Fragment } from "react"
import styled from "styled-components"
import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default"
import "photoswipe/dist/default-skin/default-skin.css"
import "photoswipe/dist/photoswipe.css"

import PhotoSwipeDOM from "./photo_swipe_dom"

const openPhotoSwipe = (items, index) => {
  const swipeElement = document.querySelectorAll(".pswp")[0]

  const photoSwipeOptions = {
    index,
    showAnimationDuration: 0,
    hideAnimationDuration: 0
  }

  const gallery = new PhotoSwipe(
    swipeElement,
    PhotoSwipeUI_Default,
    items,
    photoSwipeOptions
  )

  gallery.init()
}

const renderImageGridItem = (img, galleryItems, index) => {
  return (
    <ImageGridItem
      onClick={() => openPhotoSwipe(galleryItems, index)}
      data-size={`1000x1000`}
      key={img.node.id}
      thumb={`${img.node.thumb}`}
      imgUrl={`${img.node.imgUrl}`}
      orientation={"square"}
      padding={"100%"}
      itemProp="contentUrl"
    />
  )
}

const getListOfGalleryItems = (images, imagesVisibleCount) => {
  let items = images.map(image => {
    return {
      publicId: image.node.public_id,
      src: `${image.node.imgUrl}`,
      w: image.node.width,
      h: image.node.height
    }
  })

  if (imagesVisibleCount && items.length > imagesVisibleCount) {
    items = items.slice(0, imagesVisibleCount)
  }

  return items
}

class ImageGrid extends Component {
  componentDidMount() {
    window.PhotoSwipe = PhotoSwipe
    window.PhotoSwipeUI_Default = PhotoSwipeUI_Default
  }

  render() {
    const { data } = this.props
    let imageGridColumns = 5
    const images = data.cloudinaryImage.edges
    const galleryItems = getListOfGalleryItems(images)

    return (
      <Fragment>
        <ImageGridWrapper>
          <ImageGridRow columns={imageGridColumns}>
            {images.map((img, index) =>
              renderImageGridItem(img, galleryItems, index)
            )}
          </ImageGridRow>
        </ImageGridWrapper>
        <PhotoSwipeDOM/>
      </Fragment>
    )
  }
}

const ImageGridWrapper = styled.div`
  display: block;
`

const ImageGridRow = styled.div`
  display: grid;
  grid-gap: 0.1em;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  margin-bottom: 0.11em;
`

const ImageGridItem = styled.a`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.thumb})`};
  box-shadow: none;
  padding-bottom: ${props => props.padding};
  display: inline-block;
  width: 100%;
  height: 100%;
`
export default ImageGrid
