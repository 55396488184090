import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ImageGallery from "./image_grid"
// import ImageGallery from "./hex_image_grid"
// import ImageGallery from "./tumblr_like_photo_grid"

const Gallery = () => {
  return (
    <StaticQuery
      query={imageGalleryQuery}
      render={data => (
        <ImageGallery
          data={data}
        />
      )}
    />
  )
}

const imageGalleryQuery = graphql`
    query galleryQuery {
        cloudinaryImage: allAtImages {
            edges {
                node {
                    id
                    folder
                    thumb
                    imgUrl
                    width
                    height
                    orientation
                }
            }
        }
    }
`

export default Gallery
